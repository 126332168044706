import axios from "axios";

export const PRODUCTS_URL = "/probe";

// CREATE =>  POST: add a new product to the server
export function createProduct(product) {
  return axios.post(`${PRODUCTS_URL}/CreateUpdateInstrument`, { "action": "New", ...product });
}

// READ
export function getAllProducts() {
  return axios.get(PRODUCTS_URL);
}

export function getProductById(productId,ownerOrg) {
  return axios.post(`${PRODUCTS_URL}/ReadInstrument`, { "unifiedNo": productId, "ownerOrg": ownerOrg});
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findProducts(queryParams) {
  return axios.post(`${PRODUCTS_URL}/GetAllInstruments`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateProduct(product) {
  return axios.post(`${PRODUCTS_URL}/CreateUpdateInstrument`, { "action": "Update", ...product });
}

// UPDATE Status
export function updateStatusForProducts(ids, status) {
  return axios.post(`${PRODUCTS_URL}/updateStatusForProducts`, {
    ids,
    status
  });
}

// DELETE => delete the product from the server
export function deleteProduct(productId,ownerOrg) {
  return axios.post(`${PRODUCTS_URL}/ApproveInstrument`,{
    "action":"Approve",
    "unifiedNo": productId,
    "ownerOrg": ownerOrg
 });
}

// DELETE Products by ids
export function deleteProducts(ids) {
  return axios.post(`${PRODUCTS_URL}/deleteProducts`, { ids });
}
