import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export default [
  {
    id: 1,
    username: "admin",
    password: "demo1",
    email: "admin@demo.com",
    accessToken: "access-token-8f3ae836da744329a6f93bf20594b5cc",
    refreshToken: "access-token-f8c137a2c98743f48b643e71161d90aa",
    roles: [1], // Administrator
    pic: toAbsoluteUrl("/media/users/default.jpg"),
    fullname: "Bilal",
    occupation: "Ministry of Education",
    companyName: "Dubai",
    phone: "456669067890",
    address: {
      addressLine: "L-12-20 Vertex, Cybersquare",
      city: "San Francisco",
      state: "California",
      postCode: "45000"
    },
    socialNetworks: {
      linkedIn: "https://linkedin.com/admin",
      facebook: "https://facebook.com/admin",
      twitter: "https://twitter.com/admin",
      instagram: "https://instagram.com/admin"
    },
    components: [
      {
        label: "root",
        tabs: [{
          tabLabel: "root",
          permission: [
            {
              label: "Dashboard",
              anchor: "/dashboard",
              icon: "menu-icon flaticon2-line-chart",
              access: ["/getPermission"]
            },
            {
              label: "Document List",
              anchor: "/metaronical/products",
              icon: "menu-icon flaticon-doc",
              access: ["/getPermission"]
            },
            {
              label: "Verify Document",
              anchor: "/metaronical/verifyDocument",
              icon: "menu-icon flaticon2-shield",
              access: ["/getPermission"]
            }
          ]
        }]


      },
      {

        label: "Under the Hood",
        tabs: [{
          tabLabel: "Settings",
          tabURI: "/metaronical/customers",
          icon: "menu-icon flaticon-settings-1",
          permission: [
            {
              label: "Users",
              anchor: "/metaronical/customers",
              icon: "menu-bullet menu-bullet-dot",
              access: ["/getPermission"]
            },
            {
              label: "Roles",
              anchor: "/metaronical/roles",
              icon: "menu-bullet menu-bullet-dot",
              access: ["/getPermission"]
            },
            // {
            //   label: "Transaction Monitoring",
            //   anchor: "/metaronical/txMonitor",
            //   icon: "menu-bullet menu-bullet-dot",
            //   access: ["/getPermission"]
            // },
            {
              label: "Document Types",
              anchor: "/metaronical/docType",
              icon: "menu-bullet menu-bullet-dot",
              access: ["/getPermission"]
            }
          ]
        }]


      },
      {
        label: "Templates",
        tabs: [{
          tabLabel: "My Dev help",
          tabURI: "/builder",
          icon: "menu-icon flaticon2-expand",
          permission: [

            {
              "label": "Builder",
              "anchor": "/builder",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/builder"
              ]
            },
            {
              "label": "Inputs",
              "anchor": "/google-material/inputs",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/inputs"
              ]
            },
            {
              "label": "Autocomplete",
              "anchor": "/google-material/inputs/autocomplete",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/inputs/autocomplete"
              ]
            },
            {
              "label": "Buttons",
              "anchor": "/google-material/inputs/buttons",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/inputs/buttons"
              ]
            },
            {
              "label": "Checkboxes",
              "anchor": "/google-material/inputs/checkboxes",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/inputs/checkboxes"
              ]
            },
            {
              "label": "Pickers",
              "anchor": "/google-material/inputs/pickers",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/inputs/pickers"
              ]
            },
            {
              "label": "Radio Buttons",
              "anchor": "/google-material/inputs/radio-buttons",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/inputs/radio-buttons"
              ]
            },
            {
              "label": "Selects",
              "anchor": "/google-material/inputs/selects",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/inputs/selects"
              ]
            },
            {
              "label": "Switches",
              "anchor": "/google-material/inputs/switches",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/inputs/switches"
              ]
            },
            {
              "label": "Text Fields",
              "anchor": "/google-material/inputs/text-fields",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/inputs/text-fields"
              ]
            },
            {
              "label": "Transfer List",
              "anchor": "/google-material/inputs/transfer-list",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/inputs/transfer-list"
              ]
            },
            {
              "label": "Bottom Navigation",
              "anchor": "/google-material/navigation/bottom-navigation",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/navigation/bottom-navigation"
              ]
            },
            {
              "label": "Breadcrumbs",
              "anchor": "/google-material/navigation/breadcrumbs",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/navigation/breadcrumbs"
              ]
            },
            {
              "label": "Drawer",
              "anchor": "/google-material/navigation/drawer",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/navigation/drawer"
              ]
            },
            {
              "label": "Links",
              "anchor": "/google-material/navigation/links",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/navigation/links"
              ]
            },
            {
              "label": "Menus",
              "anchor": "/google-material/navigation/menus",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/navigation/menus"
              ]
            },
            {
              "label": "Steppers",
              "anchor": "/google-material/navigation/steppers",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/navigation/steppers"
              ]
            },
            {
              "label": "Tabs",
              "anchor": "/google-material/navigation/tabs",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/navigation/tabs"
              ]
            },
            {
              "label": "App Bar",
              "anchor": "/google-material/surfaces/app-bar",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/surfaces/app-bar"
              ]
            },
            {
              "label": "Paper",
              "anchor": "/google-material/surfaces/paper",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/surfaces/paper"
              ]
            },
            {
              "label": "Cards",
              "anchor": "/google-material/surfaces/cards",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/surfaces/cards"
              ]
            },
            {
              "label": "Expansion Panels",
              "anchor": "/google-material/surfaces/expansion-panels",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/surfaces/expansion-panels"
              ]
            },
            {
              "label": "Progress",
              "anchor": "/google-material/feedback/progress",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/feedback/progress"
              ]
            },
            {
              "label": "Dialogs",
              "anchor": "/google-material/feedback/dialogs",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/feedback/dialogs"
              ]
            },
            {
              "label": "Snackbars",
              "anchor": "/google-material/feedback/snackbars",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/feedback/snackbars"
              ]
            },
            {
              "label": "Data Displays",
              "anchor": "/google-material/data-displays",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/data-displays"
              ]
            },
            {
              "label": "Avatars",
              "anchor": "/google-material/data-displays/avatars",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/data-displays/avatars"
              ]
            },
            {
              "label": "Badges",
              "anchor": "/google-material/data-displays/badges",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/data-displays/badges"
              ]
            },
            {
              "label": "Chips",
              "anchor": "/google-material/data-displays/chips",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/data-displays/chips"
              ]
            },
            {
              "label": "Dividers",
              "anchor": "/google-material/data-displays/dividers",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/data-displays/dividers"
              ]
            },
            {
              "label": "Icons",
              "anchor": "/google-material/data-displays/icons",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/data-displays/icons"
              ]
            },
            {
              "label": "Lists",
              "anchor": "/google-material/data-displays/lists",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/data-displays/lists"
              ]
            },
            {
              "label": "Tables",
              "anchor": "/google-material/data-displays/tables",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/data-displays/tables"
              ]
            },
            {
              "label": "Tooltips",
              "anchor": "/google-material/data-displays/tooltips",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/data-displays/tooltips"
              ]
            },
            {
              "label": "Typography",
              "anchor": "/google-material/data-displays/typography",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/data-displays/typography"
              ]
            },
            {
              "label": "Utils",
              "anchor": "/google-material/utils",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/utils"
              ]
            },
            {
              "label": "Click Away Listener",
              "anchor": "/google-material/utils/click-away-listener",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/utils/click-away-listener"
              ]
            },
            {
              "label": "No Ssr",
              "anchor": "/google-material/utils/no-ssr",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/utils/no-ssr"
              ]
            },
            {
              "label": "Popover",
              "anchor": "/google-material/utils/popover",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/utils/popover"
              ]
            },
            {
              "label": "Popper",
              "anchor": "/google-material/utils/popper",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/utils/popper"
              ]
            },
            {
              "label": "Portal",
              "anchor": "/google-material/utils/portal",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/utils/portal"
              ]
            },
            {
              "label": "Transitions",
              "anchor": "/google-material/utils/transitions",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/utils/transitions"
              ]
            },
            {
              "label": "Use Media Query",
              "anchor": "/google-material/utils/use-media-query",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/utils/use-media-query"
              ]
            },
            {
              "label": "Layout",
              "anchor": "/google-material/layout",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/layout"
              ]
            },
            {
              "label": "Box",
              "anchor": "/google-material/layout/box",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/layout/box"
              ]
            },
            {
              "label": "Container",
              "anchor": "/google-material/layout/container",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/layout/container"
              ]
            },
            {
              "label": "Grid",
              "anchor": "/google-material/layout/grid",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/layout/grid"
              ]
            },
            {
              "label": "Grid List",
              "anchor": "/google-material/layout/grid-list",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/layout/grid-list"
              ]
            },
            {
              "label": "Hidden",
              "anchor": "/google-material/layout/hidden",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/google-material/layout/hidden"
              ]
            },
            {
              "label": "Alert",
              "anchor": "/react-bootstrap/alert",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/react-bootstrap/alert"
              ]
            },
            {
              "label": "Badge",
              "anchor": "/react-bootstrap/badge",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/react-bootstrap/badge"
              ]
            },
            {
              "label": "Breadcrumb",
              "anchor": "/react-bootstrap/breadcrumb",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/react-bootstrap/breadcrumb"
              ]
            },
            {
              "label": "Buttons",
              "anchor": "/react-bootstrap/buttons",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/react-bootstrap/buttons"
              ]
            },
            {
              "label": "Button Group",
              "anchor": "/react-bootstrap/button-group",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/react-bootstrap/button-group"
              ]
            },
            {
              "label": "Cards",
              "anchor": "/react-bootstrap/cards",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/react-bootstrap/cards"
              ]
            },
            {
              "label": "Carousel",
              "anchor": "/react-bootstrap/carousel",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/react-bootstrap/carousel"
              ]
            },
            {
              "label": "Dropdowns",
              "anchor": "/react-bootstrap/dropdowns",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/react-bootstrap/dropdowns"
              ]
            },
            {
              "label": "Forms",
              "anchor": "/react-bootstrap/forms",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/react-bootstrap/forms"
              ]
            },
            {
              "label": "Input Group",
              "anchor": "/react-bootstrap/input-group",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/react-bootstrap/input-group"
              ]
            },
            {
              "label": "Images",
              "anchor": "/react-bootstrap/images",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/react-bootstrap/images"
              ]
            },
            {
              "label": "Figures",
              "anchor": "/react-bootstrap/figures",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/react-bootstrap/figures"
              ]
            },
            {
              "label": "Jumbotron",
              "anchor": "/react-bootstrap/jumbotron",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/react-bootstrap/jumbotron"
              ]
            },
            {
              "label": "List Group",
              "anchor": "/react-bootstrap/list-group",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/react-bootstrap/list-group"
              ]
            },
            {
              "label": "Modal",
              "anchor": "/react-bootstrap/modal",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/react-bootstrap/modal"
              ]
            },
            {
              "label": "Navs",
              "anchor": "/react-bootstrap/navs",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/react-bootstrap/navs"
              ]
            },
            {
              "label": "Navbar",
              "anchor": "/react-bootstrap/navbar",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/react-bootstrap/navbar"
              ]
            },
            {
              "label": "Overlays",
              "anchor": "/react-bootstrap/overlays",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/react-bootstrap/overlays"
              ]
            },
            {
              "label": "Pagination",
              "anchor": "/react-bootstrap/pagination",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/react-bootstrap/pagination"
              ]
            },
            {
              "label": "Popovers",
              "anchor": "/react-bootstrap/popovers",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/react-bootstrap/popovers"
              ]
            },
            {
              "label": "Progress",
              "anchor": "/react-bootstrap/progress",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/react-bootstrap/progress"
              ]
            },
            {
              "label": "Spinners",
              "anchor": "/react-bootstrap/spinners",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/react-bootstrap/spinners"
              ]
            },
            {
              "label": "Table",
              "anchor": "/react-bootstrap/table",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/react-bootstrap/table"
              ]
            },
            {
              "label": "Tabs",
              "anchor": "/react-bootstrap/tabs",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/react-bootstrap/tabs"
              ]
            },
            {
              "label": "Tooltips",
              "anchor": "/react-bootstrap/tooltips",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/react-bootstrap/tooltips"
              ]
            },
            {
              "label": "Toasts",
              "anchor": "/react-bootstrap/toasts",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/react-bootstrap/toasts"
              ]
            },
            {
              "label": "Error V1",
              "anchor": "/error/error-v1",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/error/error-v1"
              ]
            },
            {
              "label": "Error V2",
              "anchor": "/error/error-v2",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/error/error-v2"
              ]
            },
            {
              "label": "Error V3",
              "anchor": "/error/error-v3",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/error/error-v3"
              ]
            },
            {
              "label": "Error V4",
              "anchor": "/error/error-v4",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/error/error-v4"
              ]
            },
            {
              "label": "Error V5",
              "anchor": "/error/error-v5",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/error/error-v5"
              ]
            },
            {
              "label": "Error V6",
              "anchor": "/error/error-v6",
              "icon": "menu-bullet menu-bullet-dot",
              "access": [
                "/error/error-v6"
              ]
            }
          ]


        }]
      }
    ]
  },
  {
    id: 2,
    username: "user",
    password: "demo",
    email: "user@demo.com",
    accessToken: "access-token-6829bba69dd3421d8762-991e9e806dbf",
    refreshToken: "access-token-f8e4c61a318e4d618b6c199ef96b9e55",
    roles: [2], // Manager
    pic: toAbsoluteUrl("/media/users/100_2.jpg"),
    fullname: "Megan",
    occupation: "Deputy Head of Keenthemes in New York office",
    companyName: "Keenthemes",
    phone: "456669067891",
    address: {
      addressLine: "3487  Ingram Road",
      city: "Greensboro",
      state: "North Carolina",
      postCode: "27409"
    },
    socialNetworks: {
      linkedIn: "https://linkedin.com/user",
      facebook: "https://facebook.com/user",
      twitter: "https://twitter.com/user",
      instagram: "https://instagram.com/user"
    }
  },
  {
    id: 3,
    username: "guest",
    password: "demo",
    email: "guest@demo.com",
    accessToken: "access-token-d2dff7b82f784de584b60964abbe45b9",
    refreshToken: "access-token-c999ccfe74aa40d0aa1a64c5e620c1a5",
    roles: [3], // Guest
    pic: toAbsoluteUrl("/media/users/default.jpg"),
    fullname: "Ginobili Maccari",
    occupation: "CFO",
    companyName: "Keenthemes",
    phone: "456669067892",
    address: {
      addressLine: "1467  Griffin Street",
      city: "Phoenix",
      state: "Arizona",
      postCode: "85012"
    },
    socialNetworks: {
      linkedIn: "https://linkedin.com/guest",
      facebook: "https://facebook.com/guest",
      twitter: "https://twitter.com/guest",
      instagram: "https://instagram.com/guest"
    }
  }
];
