import React from "react";
import {
    MixedWidget1,
    MixedWidget14,
    ListsWidget9,
    StatsWidget11,
    StatsWidget12,
    ListsWidget1,
    AdvanceTablesWidget2,
    AdvanceTablesWidget4,
    ListsWidget3,
    ListsWidget4,
    ListsWidget8
} from "../widgets";
export function Demo1Dashboard({history}) {
    return (<>
            <div className="row">
            
            

                <div className="col-xxl-4">
                    <StatsWidget11 className="card-stretch card-stretch-half gutter-b"/>
                    <StatsWidget12 className="card-stretch card-stretch-half gutter-b"/>
                </div>

            
                <div className="col-xxl-8">
                    <AdvanceTablesWidget2 history={history} className="card-stretch gutter-b"/>
                </div>
         
         
            </div>
   
    </>);
}
