/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import { Nav, Tab } from "react-bootstrap";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_helpers";
import {
  getBASEURL
} from "../../../../_metronic/_partials/controls";
import axios from "axios";
import moment from 'moment';
import lodash from 'lodash';
import { ProductDeleteDialog } from "../../../../app/modules/metaronical/pages/products/product-delete-dialog/ProductDeleteDialog";
export function AdvanceTablesWidget2({ history,className }) {
  const org = JSON.parse(localStorage.getItem('sessionData')).org;
  const [key, setKey] = useState("Month");
  const [show, setShow] = React.useState(false)
  const [state, setState] = React.useState({
    orglist: [],
    pending:[],
    show: false
  });
  async function getOrg(page = 1, pageSize = 10) {
    let result = await axios.post("/probe/GetAllOrgs", {
      page,
      pageSize
    });
    let list = result && result.data && result.data.data ? result.data.data : [];
    console.log("list for data", list)
    setState({ ...state, orglist: list});
  }
  async function getPending(page = 1, pageSize = 10) {
    let result = await axios.post("/probe/GetAllPendingInstruments", {
      page,
      pageSize
    });
    let list = lodash.isArray(result.data.data) ? result.data.data : [];
    setState({ ...state, pending: list});
  }
 
  const handleChange = (unifiedNo, ownerOrg) => event => {
   
    setState({ ...state, unifiedNo, ownerOrg });
    setShow(true);
  };
  React.useEffect(() => {
    // getOrg();
   // getPending();
  }, [])
  return (
    
    <div className={`card card-custom ${className}`}>
      <ProductDeleteDialog
            show={show}
            id={state.unifiedNo}
            ownerOrg={state.ownerOrg}
            onHide={() => {
              setShow(false);
              getPending();
            }}
          />
      {/* Head */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            Document Approvals
          </span>
  
        </h3>
        <div className="card-toolbar">
          <Tab.Container defaultActiveKey={key}>
            <Nav
              as="ul"
              onSelect={_key => setKey(_key)}
              className="nav nav-pills nav-pills-sm nav-dark-75"
            >
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="Month"
                  className={`nav-link py-2 px-4 ${
                    key === "Month" ? "active" : ""
                  }`}
                >
                  Month
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="Week"
                  className={`nav-link py-2 px-4 ${
                    key === "Week" ? "active" : ""
                  }`}
                >
                  Week
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="Day"
                  className={`nav-link py-2 px-4 ${
                    key === "Day" ? "active" : ""
                  }`}
                >
                  Day
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
        </div>
      </div>
      {/* Body */}
      <div className="card-body pt-3 pb-0">
        <div className="table-responsive">
          <table className="table table-borderless table-vertical-center">
            <thead>
              <tr>
                <th className="p-0" style={{ width: "50px" }} />
                <th className="p-0" style={{ minWidth: "180px" }} />
                <th className="p-0" style={{ minWidth: "100px" }} />
                <th className="p-0" style={{ minWidth: "105px" }} />
                <th className="p-0" style={{ minWidth: "120px" }} />
                <th className="p-0" style={{ minWidth: "100px" }} />
              </tr>
            </thead>
            <tbody>

             {state.pending &&state.pending.map((elem)=>{
                return(   <tr key={elem.unifiedNo}>
                  <td className="pl-0 py-4">
                    <div className="symbol symbol-50 symbol-light mr-1">
                      <span className="symbol-label">
                        <img
                          crossOrigin="anonymous" 
                          
                          src={elem.DocTypeLogo ? `${getBASEURL()}/probe/DownloadPublic/${elem.DocTypeLogo}` : "/media/svg/misc/006-plurk.svg"} 

                          className="h-50 align-self-center"
                        ></img>
                      </span>
                    </div>
                  </td>
                  <td className="pl-0">
                    <a
                      href="#"
                      className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >
                      {elem.DocTypeName} {`(${elem.unifiedNo})`}
                    </a>
                    <div>
                      <span className="font-weight-bolder">Tx:</span>{" "}
                      <a
                        className="text-muted font-weight-bold text-hover-primary"
                        href="#"
                      >
                        
                        {elem.audit && elem.audit.length>0 && elem.audit[elem.audit.length-1].txId.substring(0, 15)} {elem.audit[elem.audit.length-1].txId.length >= 15 && '...'}
                      
                      </a>
                    </div>
                  </td>
                  <td className="text-right">
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                    {elem.ownerOrgName}
                    </span>
                    <span className="text-muted font-weight-bold"></span>
                  </td>
                  <td className="text-right">
                    <span className="text-muted font-weight-500">
                    {moment.unix(elem.createDate).format('DD/MM/YY hh:mm')} 
                    </span>
                  </td>
                  <td className="text-right">
                    <span className="label label-lg label-light-primary label-inline">
                     {elem.status}
                    </span>
                  </td>
                  <td className="text-right pr-0">

                    {org.orgCode=="Met1MSP"&& <a href="javascript:;" onClick={handleChange(elem.unifiedNo, elem.ownerOrg)} className="btn btn-icon btn-light btn-sm">
                      <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Clipboard-check.svg"
                          )}
                        ></SVG>
                      </span>
                    </a>}
                   
                    <a onClick={()=>{history.push(`/metaronical/products/${elem.unifiedNo}/${elem.ownerOrg}/edit`)}} className="btn btn-icon btn-light btn-sm mx-3">
                      <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Write.svg"
                          )}
                        ></SVG>
                      </span>
                    </a>
                  </td>
                </tr>
                );

             })} 
           
            </tbody>
          </table>
          {state.pending.length==0&&<div className="col-md-12 text-center">No Pending Records Found</div>}
        </div>
      </div>
    </div>
  );
}
