import lodash from "lodash";
import toastr from "toastr";
import backoffice from "../config/backOffices.js";
export default function setupAxios(axios, store) {


  const  BASE_URL = backoffice.baseUrl;
  axios.interceptors.request.use(
    request => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        request.headers.Authorization = `${authToken}`;

      }
      request.baseURL = BASE_URL
      request.headers["Access-Control-Allow-Origin"] = "*";
      request.headers["Access-Control-Allow-Methods"] = "GET,PUT,POST,DELETE,PATCH,OPTIONS"
      console.log("intercepting", request)
      return request;
    },
    err => Promise.reject(err)
  );

  axios.interceptors.response.use(

    response => {
      toastr.options = {
        "closeButton": false,
        "debug": false,
        "newestOnTop": false,
        "progressBar": false,
        "positionClass": "toast-top-right",
        "preventDuplicates": false,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
      };
      if (response.data && response.data.error) {
        if(response.data.message=="No records Found!"){
          return response;
        }
        toastr.error(response.data.data.message, response.data.message);
      } else if (!response.data.error && lodash.isEmpty(response.data.data)) {
        toastr.success("Action Completed Succesfully!");
      }
      return response;
    },
    err => Promise.reject(err)
  );
}




