import React, { useMemo } from "react";

import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import {
  getBASEURL
} from "../../../../../_metronic/_partials/controls";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useDropzone } from 'react-dropzone';
import FaceIcon from "@material-ui/icons/FileCopyOutlined";
import ShieldIcon from '@material-ui/icons/Lock';
import EditOff from '@material-ui/icons/BlurOff';
import AccessTime from '@material-ui/icons/AccessTime';
import axios from "axios";
import { sha512 } from 'js-sha512';
import toastr from "toastr";
const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: '70px',
  borderWidth: 2,
  borderRadius: 30,
  borderStyle: 'dashed',
  backgroundColor: '#fff',
  color: 'rgb(51 104 73)',
  outline: 'none',
  zIndex: 999,
  top: "15%",
  minHeight: 200,
  width: "90%",
  right: 30,
  position: 'absolute',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: 'rgb(51 104 73)'
};

const acceptStyle = {
  borderColor: 'rgb(51 104 73)'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

function StyledDropzone({ setValues }) {
  const onDrop = React.useCallback(acceptedFiles => {
    console.log(acceptedFiles)
    if (acceptedFiles.length > 0) {
      new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = function () {
          console.log(reader.result);

          axios.post("/probe/VerifyInstrument", {
            "hash": sha512.hex(reader.result)
          }).then((elem) => {
            if (!elem.data.error) {
              setValues(elem.data.data)
              toastr.success("Document Verified Succesfully!");
            }

          })
        };
      });
    } else {
      toastr.error("File is required!");
    }

  }, [])

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({ onDrop });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  return (
    <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 center">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <FaceIcon style={{ fontSize: "80px" }} />
        <b style={{ fontSize: "13px" }}>Drag and drop or browse your files</b>


      </div>
    </div>
  );
}
const useStyles = makeStyles({

  media: {
    height: 500,
  },
});






export function VerifyDocument({ history }) {

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    data: []
  });
  const [values, setValues] = React.useState("");
  async function getType(page = 1, pageSize = 10) {
    let result = await axios.post("/probe/GetDocTypes", {
      page,
      pageSize
    });
    let list = result && result.data && result.data.data ? result.data.data.list : [];
    console.log("list for data", list)
    setState({ ...state, data: list });
  }




  React.useEffect(() => {
    getType();
    setValues("")
  }, [])
  const classes = useStyles();

  return (
    <>
      <div >



        {!values && <StyledDropzone setValues={setValues} onDrop={acceptedFiles => console.log(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Drag and drop or browse your files</p>
              </div>
            </section>
          )}
        </StyledDropzone>}

        {values.hash &&
          <div className="text-grey" style={{ position: "absolute", top: "25%", left: "10%", color: "#FFF", width: "90%", fontWeight: "900" }}>
            <i>{`This document is verified and has a unique fingerprint: ${values.hash.substring(0, 15)}, along with a unified reference number: ${values.unifiedNo}.`}</i>
          </div>
        }



      </div>

    

          {state.data.map((elem) => {
            return (<div key={elem.docTypeId} className="col-md-4 mr-2">
              <div className="card card-custom">
                <div className="card-header card-header-right ribbon ribbon-clip ribbon-left">

                  <div className="ribbon-target " style={{ top: "12px", backgroundColor: "#EEE" }}>
                    <img className="symbol-label" style={{ maxWidth: "30px" }} crossorigin="anonymous" src={!elem.logo ? "/media/users/default.jpg" : `${getBASEURL()}/probe/DownloadPublic/${elem.logo}`} />
                    {/* <i className="fa fa-star text-white"></i><span class="ribbon-inner bg-warning"></span>{elem.owner} */}
                  </div>
                  <div className="card-title" style={{ fontSize: "12px" }}>
                    {elem.name}
                  </div>
                </div>
              </div>
            </div>)
          })}
        
    </>
  );
}
