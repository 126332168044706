import React from "react";
import {useField, useFormikContext} from "formik";
import DatePicker from "react-datepicker";
import moment from "moment";
const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function DatePickerField({ ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);
  return (
    <>
      {props.label && <label>{props.label}</label>}
      <DatePicker
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        style={{ width: "100%"}}
        wrapperClassName="w-100"
        {...field}
        {...props}
        format="DD/MM/YYYY"
        selected={(field.value && new Date(field.value)) || null}
        onChange={val => {
          console.log(val, typeof val)
          if(typeof val == 'object')
           setFieldValue(field.name, moment(val).format('MM/DD/YYYY'));
          else 
           setFieldValue(field.name, val);
        }}
      />
      {errors[field.name] && touched[field.name]  && (
        <div className="invalid-datepicker-feedback">
          {errors[field.name].toString()}
        </div>
      ) }
    </>
  );
}
