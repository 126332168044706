/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  let components = JSON.parse(localStorage.getItem('menu')) || [];
  return (


    <ul className={`menu-nav ${layoutProps.ulClasses}`}>
      {/* begin::Menu Nav */}

      {components.length > 0 && components.map((e, index) => {
        return (
          <>



            {e.label !== 'root' && <li className="menu-section " key={"mnu01" + index}>
              <h4 className="menu-text">{e.label}</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>}

            {e.tabs.map((item, index) => {
              if (item.tabLabel !== 'root') {
                return (
                  <li
                    key={"mnu02" + index}
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      item.tabURI || "/", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link menu-toggle" to={item.tabURI|| "/"}>
                      <i className={item.icon}>
                        <span />
                      </i>
                      <span className="menu-text">{item.tabLabel}</span>
                    </NavLink>
                    <div className="menu-submenu">
                      <i className="menu-arrow" />
                      <ul className="menu-subnav">
                        <li className="menu-item menu-item-parent" key={"mnnn02" + index} aria-haspopup="true">
                          <span className="menu-link">
                            <span className="menu-text">{item.tabLabel}</span>
                          </span>
                        </li>


                        {item.permission.map((mItem, mIndex) => {
                          return (<li
                            key={"mnu03" + mIndex}
                            className={`menu-item ${getMenuItemActive(
                              mItem.anchor
                            )}`}
                            aria-haspopup="true"
                          >
                            <NavLink className="menu-link" to={mItem.anchor}>
                              <i className={mItem.icon}>
                                <span />
                              </i>
                              <span className="menu-text">{mItem.label}</span>
                            </NavLink>
                          </li>);

                        })}
                      </ul>
                    </div>
                  </li>)

              } else {
                return (
                  item.permission.map((mItem, mIndex) => {
                    return (
                      <li
                        key={"mnu03" + mIndex}
                        className={`menu-item ${getMenuItemActive(mItem.anchor, false)}`}
                        aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to={mItem.anchor}>
                          <i className={mItem.icon}>
                            <span />
                          </i>
                          <span className="menu-text">{mItem.label}</span>
                        </NavLink>
                      </li>)
                  })
                )
              }
            })
            }
          </>);

      })
      }

    </ul>

  );
}
