export default [
  {
    VINCode: "1FTWX3D52AE575540",
    id: 1,
    docType: "Degree Certificate",
    issuingAuth: "MOE",
    createdDate: "09/30/2024",
    status: 0,
    _userId: 1,
    _createdDate: "03/31/2024",
    _updatedDate: "05/08/2015"
  },
  {
    id: 2,
   
    VINCode: "JM1NC2EF8A0293556",
    docType: "Degree Certificate",
    issuingAuth: "MOE",
    createdDate: "09/30/2024",
    status: 0,
    _userId: 1,
    _createdDate: "03/31/2024",
    _updatedDate: "05/08/2015"
  },
  {
    id: 3,
   
    VINCode: "1G6DG8E56C0973889",
    docType: "Degree Certificate",
    issuingAuth: "MOE",
    createdDate: "09/30/2024",
    status: 0,
    _userId: 1,
    _createdDate: "03/31/2024",
    _updatedDate: "05/08/2015"
  },
  {
    id: 4,

    VINCode: "2T1BU4EE6DC859114",
    docType: "Degree Certificate",
    issuingAuth: "MOE",
    createdDate: "09/30/2017",
    status: 1,
    _userId: 1,
    _createdDate: "03/31/2024",
    _updatedDate: "05/08/2015"
  }
];
