export default [
  {
    id: 1,
    firstName: "Bilal",
    lastName: "Mahroof",
    email: "admin@demo.com",
    userName: "admin@demo.com",
    gender: "Male",
    status: 1,
    dateOfBbirth: "10/14/1992",
    ipAddress: "251.237.126.210",
    type: 1,
    _userId: 1,
    _createdDate: "09/07/2016",
    _updatedDate: "05/31/2013"
  },
  {
    id: 2,
    firstName: "Abeer",
    lastName: "Al Ahli",
    email: "admin@demo2.com",
    userName: "admin@demo2.com",
    gender: "Female",
    status: 1,
    dateOfBbirth: "12/31/1998",
    ipAddress: "239.176.5.218",
    type: 1,
    _userId: 2,
    _createdDate: "03/18/2014",
    _updatedDate: "08/17/2016"
  },
  
];
