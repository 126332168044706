/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
import TextField from '@material-ui/core/TextField';
import { sha512 } from 'js-sha512';
import {
  getBASEURL
} from "../../../../../_metronic/_partials/controls";
import axios from "axios";
export function QuickUser() {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('sessionData')).userDetails;
  const org = JSON.parse(localStorage.getItem('sessionData')).org;
  const [cred, setCred] = React.useState(false);
  console.log(localStorage.getItem('sessionData'))
  const [values, setValues] = React.useState({});
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };
  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
  };
  const credClick = () => {
    setCred(true)
  };
  const closeClick = () => {
    setCred(false)
  };
  async function updatePwd() {
     await axios.post("/probe/ChangeUserPassword", {
      "action":"Update",
      "password":sha512.hex(values.newPassword),
      "oldPassword":sha512.hex(values.oldPassword)
    });
    
    setCred(false)
  }
  const changePwd = () => {
    if(!values.oldPassword || !values.newPassword){
      alert("Password is required!")
      return
    }
    updatePwd()
  };

  if (cred) {
    return (
      <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas p-10">
        <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
          <h3 className="font-weight-bold m-0">
            Update Password

          </h3>

        </div>

        <div className="offcanvas-content pr-5 mr-n5">
          <div className="d-flex align-items-center mt-5">
            <div className="d-flex flex-column">
              <div className="row">
                <div className="col-md-12">
                  <TextField
                    id="outlined-with-placeholder"
                    label="Old Password"
                    type="password"
                    value={values.oldPassword}
                    onChange={handleChange('oldPassword')}
                    margin="normal"
                    variant="outlined"
                  />
                  <TextField
                    id="outlined-with-placeholder"
                    label="New Password"
                    type="password"
                    value={values.newPassword}
                    onChange={handleChange('newPassword')}
                    margin="normal"
                    variant="outlined"
                  />
                  <div className="btn-toolbar" role="group" aria-label="Basic example">
                    <button type="button" className="btn btn-light btn-sm mr-2" onClick={closeClick}>Close</button>
                    <button type="button" className="btn btn-primary btn-sm" onClick={changePwd}>Update Password</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas p-10">
        <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
          <h3 className="font-weight-bold m-0">
            User Profile

          </h3>


          {/* <a
            href="#"
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_user_close"
          >
            <i className="ki ki-close icon-xs text-muted" />
          </a> */}
        </div>

        <div
          className="offcanvas-content pr-5 mr-n5"
        >
          <div className="d-flex align-items-center mt-5">
            <div
              className="symbol symbol-100 mr-5"
            >
              <img className="symbol-label" crossOrigin="anonymous" src={user.pic ? `${getBASEURL()}/probe/DownloadPublic/${user.pic}` : "/media/users/default.jpg"} />
              <i className="symbol-badge bg-success" />
            </div>
            <div className="d-flex flex-column">
              <a
                href="#"
                className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
              >
                {user.firstName}
              </a>
              <div className="text-muted mt-1">   {user.type + " / " + org.orgName}</div>

              <div className="navi mt-2">
                <a href="#" className="navi-item">
                  <span className="navi-link p-0 pb-2">
                    <span className="navi-icon mr-1">
                      <span className="svg-icon-lg svg-icon-primary">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Mail-notification.svg"
                          )}
                        ></SVG>
                      </span>
                    </span>
                    <span className="navi-text text-muted text-hover-primary">
                      {user.email}
                    </span>
                  </span>
                </a>
              </div>
              {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
              <div className="row">
                <div className="col-md-12">

                  <div className="btn-toolbar" role="group" aria-label="Basic example">
                    <button type="button" className="btn btn-danger btn-sm mr-2" onClick={logoutClick}>Sign out</button>

                    <button type="button" className="btn btn-primary btn-sm" onClick={credClick}>Credentials</button>
                  </div>

                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    );
  }
}
